import React from 'react';
import { CursorContent } from './CursorContent';
import { MousePosition } from './MousePosition';
import { GlobalState } from './GlobalState';

const providers = [
  <CursorContent.Provider />,
  <MousePosition.Provider />,
  <GlobalState.Provider />,
];

const Store = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => React.cloneElement(parent, { children }),
    initial
  );

export { Store, CursorContent, MousePosition, GlobalState };
