import styled, { css } from 'styled-components';

import { colors, layout, layers, typography, breakpoints } from '../../theme';

export const Wrapper = styled.nav`
  color: ${colors.BLACK};
  display: flex;

  a {
    color: inherit;
    text-decoration: none;

    &.active {
      color: inherit;
      opacity: 0.4;
      color: ${colors.BLACK};
    }

    &.active-hidden {
      visibility: hidden;
    }
  }
`;

export const CategoryName = styled.h2``;

export const ProjectList = styled.ul`
  opacity: 0;
  padding-top: ${layout.SPACING}rem;
  position: absolute;
  transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
  visibility: hidden;
  width: 30rem;
`;

export const ProjectItem = styled.li`
  a {
    color: ${colors.BLACK};

    &:hover,
    &.active {
      opacity: 0.4;
      color: ${colors.BLACK};
    }
  }
`;

export const List = styled.ul`
  display: flex;
  width: 100%;
`;

export const Item = styled.li`
  cursor: default;
  padding: 0 ${layout.SPACING / 2}rem;
  position: relative;
  z-index: ${layers.NAV};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  html.touch & {
    padding: 0 ${layout.SPACING / 3}rem;
  }

  h2 {
    opacity: ${props => (props.isHovered ? 0.4 : 1)};

    html.touch & {
      display: inline-block;
      margin: -5px -8px;
      padding: 5px 8px;
    }
  }

  ${props =>
    props.isHovered &&
    css`
      ${ProjectList} {
        opacity: 1;
        visibility: visible;
      }
    `}

  ${props =>
    props.isActive &&
    css`
      ${CategoryName} {
        opacity: 0.4;
        color: ${colors.BLACK};
      }
    `}
`;

export const ThumbnailsToggle = styled.a`
  cursor: pointer;
  opacity: ${props => (props.isHidden ? 0.05 : 1)};
  position: absolute;
  right: ${layout.SPACING_X}rem;
  top: ${layout.SPACING_Y}rem;
  /* transition: ${props =>
    props.isHidden
      ? 'opacity 0.1s ease-in-out'
      : 'opacity 0.5s ease-in-out'}; */
  z-index: ${layers.NAV};

  &:hover {
    opacity: 0.4;
    color: ${colors.BLACK};
  }

  @media ${breakpoints.MOBILE_MQ} {
    display: none;
  }
`;

export const Mask = styled.div`
  background: ${props => props.backgroundColour || colors.WHITE};
  bottom: 0;
  left: 0;
  opacity: ${props => (props.isVisible ? 0.95 : 0)};
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: ${props =>
    props.isVisible
      ? 'opacity 0.1s ease-in-out, visibility 0.1s ease-in-out'
      : 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out'};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  z-index: ${layers.NAV_MASK};
`;
