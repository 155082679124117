import React, { useState, useEffect } from 'react';
import Image from 'gatsby-image';
import { animateScroll } from 'react-scroll';
import { Wrapper, Scroll, ScrollDistance, Logo, ImageWrapper } from './style';
import { breakpoints, layout } from '../../theme';

const Intro = ({ coverImage, coverImageMobile }) => {
  const [hasScrolledPast, setHasScrolledPast] = useState();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollTop, setScrollTop] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleWindowResize = () => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let scaleProgress = scrollTop / (windowHeight * 2);
  scaleProgress = scaleProgress * scaleProgress * scaleProgress * scaleProgress;
  if (scaleProgress > 1) scaleProgress = 1;

  let translateProgress = scrollTop / windowHeight;
  if (translateProgress > 1) translateProgress = 1;

  const translateY = `calc(${translateProgress} * calc(50vh - ${layout.SPACING_Y *
    3}rem))`;
  const scaleStart = 1;
  const scaleEnd = 201;
  const scale = scaleStart + scaleProgress * (scaleEnd - scaleStart);

  return (
    <Wrapper
      isHidden={hasScrolledPast}
      onClick={() => {
        if (isAnimating) return;
        animateScroll.scrollToBottom({
          duration: 700,
          containerId: 'intro-scroll',
          smooth: true,
        });
        setIsAnimating(true);
      }}
    >
      <Scroll
        id="intro-scroll"
        onScroll={evt => {
          if (isAnimating) {
            setScrollTop(evt.target.scrollTop);
            if (evt.target.scrollTop > windowHeight * 2 - 1)
              setHasScrolledPast(true);
          } else {
            animateScroll.scrollToBottom({
              duration: 700,
              containerId: 'intro-scroll',
              smooth: true,
            });
            setIsAnimating(true);
          }
        }}
      >
        <ScrollDistance />
      </Scroll>
      <Logo
        style={{
          transform: `translate3d(0, ${translateY}, 0)`,
        }}
      >
        <svg
          width="551px"
          height="78px"
          viewBox="0 0 551 78"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            transform: `translate3d(-50%, -50%, 0)`,
            width: `${100 * scale}%`,
          }}
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              transform="translate(-1.000000, 0.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path d="M1.42,57.03 L2.42,51.47 L20.58,48.17 L18.93,57.34 C17.93,62.9 20.27,64.65 23.26,64.65 C27.07,64.65 27.79,61.77 28.31,58.89 L28.82,55.89 C29.44,52.39 29.23,50.22 25.73,48.06 L15.12,41.58 C7.5,36.95 6.68,28.81 7.91,23.04 L9.15,17.04 C10.9,8.59 15.84,0.97 31.7,0.97 C42.83,0.97 54.78,4.68 52.51,20.23 L51.69,25.79 L33.97,28.88 L35.42,21.09 C36.42,15.63 34.6,13.57 31.42,13.57 C27.81,13.57 26.57,16.25 26.06,18.72 L25.52,21.17 C24.91,24.17 25.52,27.17 28.72,29.1 L39.42,35.61 C45.1529665,38.8689269 48.20688,45.3895531 47.04,51.88 L45.7,59.5 C43.95,69.29 39.32,77.63 22.53,77.63 C9.87,77.63 -1.36,72.17 1.42,57.03 Z"></path>
              <path d="M50.55,52.6 L56.11,21.29 C57.87,11.1 63.94,1 80.53,1 C94.94,1 104.94,6.77 102.05,23.46 L100.92,29.74 L82.79,32.93 L84.79,21.81 C85.71,16.25 83.45,14.19 80.15,14.19 C76.15,14.19 74.69,16.97 73.97,20.98 L67.9,55.28 C66.66,62.28 69.03,64.34 72.33,64.34 C76.14,64.34 77.68,61.25 78.61,56.34 L80.67,44.81 L97.52,48.17 L96.08,56.52 C94.33,66.92 89.08,77.63 71.36,77.63 C58.17,77.63 47.15,72.07 50.55,52.6 Z"></path>
              <path d="M129.14,75.88 L129.66,60.88 L117.42,60.88 L112.68,75.88 L95.05,75.88 L121.83,2.75 L144.59,2.75 L145.72,75.88 L129.14,75.88 Z M130.07,48.69 L130.89,26.55 C131,23.25 131,20.55 131.3,16.55 L131,16.55 C130,20.55 129.14,23.25 128.11,26.55 L121.21,48.69 L130.07,48.69 Z"></path>
              <path d="M164.42,2.75 L182.65,2.75 L187.08,32.31 C187.59,35.71 188.21,39.21 188.42,42.41 L188.72,42.41 C189.45,37.36 190.27,32.31 191.09,27.41 L195.42,2.75 L210.25,2.75 L197.38,75.88 L181.05,75.88 L175.8,42.51 C175.18,38.6 174.46,34.99 174.25,31.51 L173.95,31.51 C173.23,36.14 172.61,40.88 171.68,45.83 L166.42,75.88 L151.49,75.88 L164.42,2.75 Z"></path>
              <path d="M219.68,2.75 L238.01,2.75 C253.87,2.75 264.01,10.27 261.49,25.62 L256.96,53.32 C254.08,71.25 244.09,75.88 227.96,75.88 L206.8,75.88 L219.68,2.75 Z M229.77,63.75 C234.71,63.75 237.7,61.58 238.94,54.68 L243.78,26.77 C245.01,19.46 243.37,14.93 238.01,14.93 L234.71,14.93 L226.17,63.75 L229.77,63.75 Z"></path>
              <polygon points="288.17 15.63 285.29 31.8 302.49 31.8 300.22 44.67 283.02 44.67 279.83 63.01 299.09 63.01 296.82 75.88 260.36 75.88 273.24 2.75 308.98 2.75 306.71 15.63"></polygon>
              <path d="M356.15,20.88 L355.84,23.25 C354.71,31.7 350.59,36.64 342.04,38.25 C349.76,39.9 352.75,46.07 351.1,56.07 L350.79,57.92 C349.25,67.5 343.38,75.84 327.41,75.84 L303.11,75.84 L315.98,2.71 L337.42,2.71 C348.94,2.75 357.8,8.62 356.15,20.88 Z M329.06,43.75 L325.97,43.75 L322.37,64.45 L325.42,64.45 C331.18,64.45 332.42,61.77 333.35,56.62 L334.17,51.78 C335.24,45.5 333.28,43.75 329.06,43.75 L329.06,43.75 Z M333.9,14.17 L331.22,14.17 L327.82,33.32 L331.12,33.32 C335.86,33.32 337.3,30.65 338.23,25.39 L338.85,21.79 C339.77,15.94 337.61,14.17 333.9,14.17 Z"></path>
              <polygon points="382 15.63 379.12 31.8 396.32 31.8 394.05 44.67 376.85 44.67 373.66 63.01 392.92 63.01 390.65 75.88 354.19 75.88 367.07 2.75 402.81 2.75 400.54 15.63"></polygon>
              <path d="M450.8,23.04 L450.08,27.04 C448.74,34.48 446.42,40.17 439.27,43.17 L443.08,75.92 L425.67,75.92 L423.92,46.77 L423.82,46.88 L419.29,46.88 L414.14,75.88 L396.94,75.88 L409.81,2.75 L429.17,2.75 C443.8,2.75 453.59,7.29 450.8,23.04 Z M423.8,36.33 C429.15,36.33 431.21,33.45 432.35,27.16 L433.27,22.01 C434.27,16.01 431.94,14.19 428.02,14.19 L425.02,14.19 L421.11,36.33 L423.8,36.33 Z"></path>
              <path d="M450.8,49.31 L455.75,22.01 C457.75,11.4 463.75,1.01 481.09,1.01 C493.14,1.01 504.98,6.01 501.58,23.77 L501.17,26.04 L483.25,29.17 L484.25,22.78 C485.48,15.06 482.91,13.51 480.25,13.51 C475.93,13.51 474.18,17.51 473.45,21.34 L467.79,53.34 C466.04,63.03 469.13,64.57 472.22,64.57 C477.37,64.57 478.81,60.35 479.63,55.57 L480.97,47.85 L474.42,47.85 L476.58,35.07 L499.58,35.07 L492.42,75.88 L480.06,75.88 L481.42,64.76 C478.85,72.59 474.32,77.63 464.84,77.63 C454.72,77.63 446.68,71.97 450.8,49.31 Z"></path>
              <path d="M500.42,57.03 L501.42,51.47 L519.55,48.17 L517.9,57.34 C516.9,62.9 519.24,64.65 522.23,64.65 C526.04,64.65 526.76,61.77 527.23,58.89 L527.75,55.89 C528.36,52.39 528.16,50.22 524.66,48.06 L514.05,41.57 C506.43,36.94 505.6,28.8 506.84,23.03 L508.07,17.03 C509.83,8.58 514.77,0.96 530.63,0.96 C541.75,0.96 553.7,4.67 551.44,20.22 L550.61,25.78 L532.9,28.87 L534.34,21.05 C535.34,15.59 533.52,13.53 530.34,13.53 C526.74,13.53 525.5,16.21 524.99,18.68 L524.47,21.15 C523.85,24.15 524.47,27.15 527.66,29.08 L538.42,35.61 C544.146044,38.8753194 547.197537,45.3907784 546.04,51.88 L544.7,59.5 C542.95,69.29 538.31,77.63 521.52,77.63 C508.9,77.63 497.67,72.17 500.42,57.03 Z"></path>
            </g>
          </g>
        </svg>
      </Logo>
      <ImageWrapper>
        {coverImage ? (
          <Image
            fluid={
              windowWidth > breakpoints.MOBILE
                ? coverImage.fluid
                : coverImageMobile.fluid
            }
            style={{ height: '100%', width: '100%' }}
          />
        ) : null}
      </ImageWrapper>
    </Wrapper>
  );
};

export default Intro;
