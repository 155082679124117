import styled from 'styled-components';

import { colors, layout, layers, typography } from '../../theme';

export const Wrapper = styled.nav`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${layers.PROJECT_NAV};

  html.touch & {
    display: none;
  }
`;

export const List = styled.ul`
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Item = styled.li`
  flex-grow: ${props => (props.spacer ? 1 : 0)};
  position: relative;
  max-width: ${props => (props.spacer ? 'none' : '20vw')};
  width: 10rem;

  a {
    bottom: 0;
    cursor: none;
    left: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
