import React, { createContext, useReducer } from 'react';

// Initial State
const initialState = {
  showThumbnails: false,
  touch: false,
};

// Context
const State = createContext(initialState);
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        showThumbnails: action.showThumbnails,
      };
    case 'setTouch':
      return {
        ...state,
        touch: action.touch,
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const GlobalState = {
  State,
  Dispatch,
  Provider,
};
