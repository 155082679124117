export const REM = 13;
export const REM_IN_VW = 1;
export const REM_MIN = 13;
export const REM_MAX = 20;

export const FONT_NAME = 'GT America';

export const FONT_FAMILY = `"${FONT_NAME}", Arial, Helvetica, sans-serif`;

export const LINE_HEIGHT = 18 / REM;
export const LINE_HEIGHT__LARGE = 22 / REM;

export const FONT_SIZE = 13 / REM;
export const FONT_SIZE__LARGE = 16 / REM;

export const LETTER_SPACING = 0;
