import styled, { css } from 'styled-components';
import { colors, layers, layout, breakpoints } from '../../theme';

export const Logo = styled.div`
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: ${layout.SPACING_Y * 3}rem;
  /* transform: translate3d(0, calc(50vh - ${layout.SPACING_Y * 3}rem), 0); */
  transform-origin: center;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
  width: 22vw;
  z-index: 1;

  @media ${breakpoints.MOBILE_MQ} {
    width: 50vw;
  }

  svg {
    height: auto;
    width: 100%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const ImageWrapper = styled.div`
  background: ${colors.WHITE};
  bottom: 0;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0s ease-out, visibility 0s ease-out;
  transform: translate3d(0, 0, 0);
  z-index: 0;
`;

export const Scroll = styled.div`
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ScrollDistance = styled.div`
  height: 300vh;
`;

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: visibility 0s ease-out 0.5s;
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
  z-index: ${layers.INTRO};

  ${props =>
    props.isHidden &&
    css`
      ${Logo},
      ${ImageWrapper} {
        opacity: 0;
        visibility: hidden;
      }
    `}
`;
