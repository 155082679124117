import React from 'react';
import Helmet from 'react-helmet';

const DefaultHead = ({ metaData }) => {
  return (
    <Helmet>
      <title>{`${metaData.metaTitle} | ${metaData.siteName}`}</title>
      <meta name="description" content={metaData.metaDescription} />
      <meta name="keywords" content={metaData.metaKeywords} />
      {metaData.favicon ? (
        <link
          rel="shortcut icon"
          href={`${metaData.favicon.fixed.src}&r=max`}
        />
      ) : null}

      <meta property="og:site_name" content={metaData.siteName} />
      <meta property="og:title" content={metaData.metaTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaData.metaDescription} />
      {metaData.metaImage ? (
        <meta property="og:image" content={metaData.metaImage.fluid.src} />
      ) : null}
    </Helmet>
  );
};

export default DefaultHead;
