import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';

import { CursorContent } from '../../store';

import ProjectName from '../ProjectName';

import { Wrapper, List, Item } from './style';

const ProjectNavigation = ({ previousProject, nextProject }) => {
  const cursorContentDispatch = useContext(CursorContent.Dispatch);

  // useEffect(() => {
  //   cursorContentDispatch({
  //     type: 'setContentByType',
  //     contentType: 'previousProject',
  //     content: <ProjectName project={previousProject} />,
  //   });
  //   cursorContentDispatch({
  //     type: 'setContentByType',
  //     contentType: 'nextProject',
  //     content: <ProjectName project={nextProject} />,
  //   });
  //   return () => {
  //     cursorContentDispatch({
  //       type: 'setContentByType',
  //       contentType: 'previousProject',
  //       content: null,
  //     });
  //     cursorContentDispatch({
  //       type: 'setContentByType',
  //       contentType: 'nextProject',
  //       content: null,
  //     });
  //   };
  // });

  return (
    <Wrapper role="navigation">
      <List>
        {previousProject ? (
          <Item>
            <Link
              onMouseMove={() =>
                cursorContentDispatch({
                  type: 'setActiveType',
                  activeType: 'previousProject',
                })
              }
              onMouseLeave={() =>
                cursorContentDispatch({
                  type: 'setActiveType',
                  activeType: null,
                })
              }
              to={previousProject.path}
            />
          </Item>
        ) : null}
        <Item spacer />
        {nextProject ? (
          <Item>
            <Link
              onMouseMove={() =>
                cursorContentDispatch({
                  type: 'setActiveType',
                  activeType: 'nextProject',
                })
              }
              onMouseLeave={() =>
                cursorContentDispatch({
                  type: 'setActiveType',
                  activeType: null,
                })
              }
              to={nextProject.path}
            />
          </Item>
        ) : null}
      </List>
    </Wrapper>
  );
};

export default ProjectNavigation;
