import React, { createContext, useReducer } from 'react';

const initialState = {
  activeType: null,
  content: {
    nextProject: 'Next project',
    previousProject: 'Previous project',
  },
};

// Context
const State = createContext(initialState);
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'setActiveType':
      return {
        ...state,
        activeType: action.activeType,
      };
    case 'setContentByType':
      return {
        ...state,
        content: {
          ...state.content,
          [action.contentType]: action.content,
        },
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const CursorContent = {
  State,
  Dispatch,
  Provider,
};
