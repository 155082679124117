import React, { createContext, useReducer } from 'react';

// Context
const State = createContext();
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        mouseX: action.mouseX,
        mouseY: action.mouseY,
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { mouseX: 0, mouseY: 0 });

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const MousePosition = {
  State,
  Dispatch,
  Provider,
};
