import React, { useEffect } from 'react';
import DefaultHead from '../DefaultHead';
import Cursor from '../Cursor';
import Navigation from '../Navigation';
import ProjectNavigation from '../ProjectNavigation';
import Intro from '../Intro';
import './reset.css';
import { GlobalStyle, Wrapper, Content } from './style';

const Layout = ({ location, pageContext, children }) => {
  const {
    metaData,
    coverImage,
    coverImageMobile,
    categories,
    pages,
    nextProject,
    previousProject,
    backgroundColour,
  } = pageContext;

  useEffect(() => {
    window.addEventListener('touchstart', function onFirstTouch() {
      document.documentElement.classList.add('touch');
      window.removeEventListener('touchstart', onFirstTouch);
    });

    return () => {
      if (typeof onFirstTouch === 'function')
        window.removeEventListener('touchstart', onFirstTouch);
    };
  });

  return (
    <Wrapper>
      <GlobalStyle />
      <DefaultHead metaData={metaData} />
      <Intro coverImage={coverImage} coverImageMobile={coverImageMobile} />
      <Cursor />
      <Navigation
        location={location}
        backgroundColour={backgroundColour}
        categories={categories}
        pages={pages}
      />
      <ProjectNavigation
        previousProject={previousProject}
        nextProject={nextProject}
      />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Layout;
