import React, { useContext, useEffect } from 'react';
import Image from 'gatsby-image';
import { CursorContent, MousePosition } from '../../store';
import { Wrapper } from './style';

const thumbnailDimensions = ({ file }) => {
  const area = 60;
  let height = Math.sqrt(
    area * (file.details.image.height / file.details.image.width)
  );
  let width = Math.sqrt(
    area * (file.details.image.width / file.details.image.height)
  );

  return {
    height: `${height}rem`,
    width: `${width}rem`,
  };
};

const Cursor = () => {
  const mousePositionDispatch = useContext(MousePosition.Dispatch);
  const { activeType, content } = useContext(CursorContent.State);
  const { mouseX, mouseY } = useContext(MousePosition.State);

  useEffect(() => {
    const handleMouseMove = evt => {
      mousePositionDispatch({
        type: 'set',
        mouseX: evt.pageX,
        mouseY: evt.pageY,
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  });

  return (
    <Wrapper
      activeType={activeType}
      style={{
        top: mouseY,
        left: mouseX,
      }}
    >
      {activeType === 'projectListThumbnail' ? (
        <Image
          fluid={content[activeType].fluid}
          style={thumbnailDimensions(content[activeType])}
        />
      ) : (
        content[activeType]
      )}
    </Wrapper>
  );
};

export default Cursor;
