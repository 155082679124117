import styled from 'styled-components';
import { layers, layout, colors } from '../../theme';

export const Wrapper = styled.div`
  color: ${colors.WHITE};
  mix-blend-mode: ${props =>
    props.activeType === 'projectListThumbnail' ? 'normal' : 'difference'};
  pointer-events: none;
  position: fixed;
  width: ${props =>
    props.activeType === 'projectListThumbnail' ? '10rem' : '100rem'};
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  z-index: ${layers.CURSOR};

  html.touch & {
    display: none;
  }
`;
