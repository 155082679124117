import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { GlobalState } from '../../store';
import ProjectName from '../ProjectName';

import {
  Wrapper,
  List,
  Item,
  CategoryName,
  ProjectList,
  ProjectItem,
  ThumbnailsToggle,
  Mask,
} from './style';

const Navigation = ({ backgroundColour, categories, location, pages }) => {
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const { showThumbnails } = useContext(GlobalState.State);
  const [hoveredItem, setHoveredItem] = useState();
  const activeCategorySlug = location.pathname.split('/')[1];
  const activeProjectSlug = location.pathname.split('/')[2];
  const pageSlugs = pages.map(page => page.slug);

  return (
    <Wrapper role="navigation">
      <Mask
        backgroundColour={backgroundColour}
        isVisible={hoveredItem && pageSlugs.indexOf(hoveredItem) === -1}
      />
      <List>
        {categories.map(category =>
          category.projects && category.projects.length > 0 ? (
            <Item
              key={category.id}
              onMouseMove={() => setHoveredItem(category.slug)}
              onMouseLeave={() => setHoveredItem()}
              isActive={activeCategorySlug === category.slug}
              isHovered={hoveredItem === category.slug}
              isCategory
            >
              <CategoryName>{category.name}</CategoryName>
              <ProjectList>
                {category.projects.map(project => (
                  <ProjectItem key={project.id}>
                    <Link
                      to={`/${category.slug}/${project.slug}`}
                      onClick={() => {
                        setHoveredItem();
                        globalStateDispatch({
                          type: 'set',
                          showThumbnails: false,
                        });
                      }}
                      activeClassName="active"
                    >
                      <ProjectName project={project} />
                    </Link>
                  </ProjectItem>
                ))}
              </ProjectList>
            </Item>
          ) : null
        )}
        {pages.map(page => (
          <Item
            key={page.id}
            onMouseMove={() => setHoveredItem(page.slug)}
            onMouseLeave={() => setHoveredItem()}
            isHovered={hoveredItem === page.slug}
          >
            <h2>
              <Link
                to={`/${page.slug}`}
                activeClassName="active"
                partiallyActive={true}
                onClick={evt => {
                  globalStateDispatch({
                    type: 'set',
                    showThumbnails: false,
                  });
                }}
              >
                {page.name}
              </Link>
            </h2>
          </Item>
        ))}
      </List>
      {activeProjectSlug ? (
        <ThumbnailsToggle
          onClick={evt => {
            evt.preventDefault();
            globalStateDispatch({
              type: 'set',
              showThumbnails: !showThumbnails,
            });
          }}
          isHidden={hoveredItem && pageSlugs.indexOf(hoveredItem) === -1}
        >
          {showThumbnails ? 'Close' : 'Overview'}
        </ThumbnailsToggle>
      ) : null}
    </Wrapper>
  );
};

export default Navigation;
