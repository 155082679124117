import styled, { createGlobalStyle } from 'styled-components';

import { colors, layout, typography, breakpoints } from '../../theme';

import GT_AMERICA_OTF from '../../fonts/GT-America-Standard-Light.otf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: fallback;
    font-family: "${typography.FONT_NAME}";
    font-style: normal;
    font-weight: normal;
    src: url('${GT_AMERICA_OTF}');
  }

  @-ms-viewport{
    width: device-width;
  }

  html {
    background: ${colors.WHITE};
    color: ${colors.BLACK};
    font-family: ${typography.FONT_FAMILY};
    font-size: ${typography.REM_IN_VW}vw;
    letter-spacing: ${typography.LETTER_SPACING}rem;

    @media screen and (min-width: ${(typography.REM_MAX /
      typography.REM_IN_VW) *
      100}px) {
      font-size: ${typography.REM_MAX}px;
    }
    
    @media screen and (max-width: ${(typography.REM_MIN /
      typography.REM_IN_VW) *
      100}px) {
      font-size: ${typography.REM_MIN}px;
    }
    
    @media ${breakpoints.MOBILE_MQ} {
      font-size: 14px;
    }

    *, *:before, *:after {
      box-sizing: border-box;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${colors.GREY};
      }
    }

    p, h1, h2, h3, h4, h5, h6, ul, ol {
      font-size: inherit;
      font-weight: normal;
      margin: 0;
    }

    ul, ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0;
      padding: 0;
    }
  }

  body {
    font-size: ${typography.FONT_SIZE}rem;
    -webkit-font-smoothing: antialiased;
    line-height: ${typography.LINE_HEIGHT}rem;
    visibility: visible !important;
  }
`;

export const Wrapper = styled.section`
  background: ${colors.WHITE};
  bottom: 0;
  left: 0;
  padding: ${layout.SPACING_Y}rem ${layout.SPACING_X}rem;
  position: fixed;
  right: 0;
  top: 0;

  @media ${breakpoints.MOBILE_MQ} {
    padding: ${layout.SPACING_Y / 2}rem ${layout.SPACING_X / 2}rem;
  }
`;

export const Content = styled.div`
  bottom: 0;
  left: 0;
  padding: ${layout.SPACING_Y + typography.LINE_HEIGHT * 2}rem
    ${layout.SPACING_X}rem;
  position: absolute;
  right: 0;
  top: 0;

  @media ${breakpoints.MOBILE_MQ} {
    padding: ${layout.SPACING_Y / 2 + typography.LINE_HEIGHT * 2}rem
      ${layout.SPACING_X / 2}rem;
  }
`;
